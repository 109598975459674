// @ts-ignore missing types
import MarkdownIt from '@esm-bundle/markdown-it';
// import mediaBucketConfig from '@/config/mediaBucketConfig'

export function useMarkdownIt() {
    const _md = new MarkdownIt({
        html: true,
        breaks: true,
        linkify: true,
        //typographer: true
    });

    const md = {
        // override the render function to automatically add target="_blank" to all links
        render: (content) => {
        let renderedContent = _md.render(content)
        return renderedContent.replace(/<a href="http/g, '<a target="_blank" href="http');
      }
    }

    // the following method taken from https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer only works with MD content (not HTML content coming from strapi)
    // instead doing the override method above but keeping this here for future reference
    // 
    // Remember old renderer, if overridden, or proxy to default renderer
    // const defaultRender = _md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
    //     return self.renderToken(tokens, idx, options);
    // };

    // _md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    //     // If you are sure other plugins can't add `target` - drop check below
    //     var aIndex = tokens[idx].attrIndex('target');

    //     if (aIndex < 0) {
    //         tokens[idx].attrPush(['target', '_blank']); // add new attribute
    //     } else {
    //         tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
    //     }

    //     // pass token to default renderer.
    //     return defaultRender(tokens, idx, options, env, self);
    // };

    return {
        md,
    };
}