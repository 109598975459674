<template>
  <v-footer absolute class="py-15 px-5 footer-container" color="themeBlack">
    <v-row class="content-container-1140">
      <v-col
        cols="12"
        md="3"
        class="d-flex flex-column align-center d-md-inline mb-5 mb-md-0"
      >
        <a
          class="footer-external-link"
          href="https://www.globalmethanehub.org/"
          target="_blank"
        >
          <v-img
            src="/images/gmh-logo@2x.png"
            alt="Global Methane Hub Logo"
            width="165"
            class="mb-8"
          ></v-img>
        </a>
        <p>
          <span class="mr-1">@{{ new Date().getFullYear() }}</span>
          <a
            class="footer-external-link"
            href="https://www.globalmethanehub.org/"
            target="_blank"
            >Global Methane Hub</a
          >
        </p>
      </v-col>
      <v-col cols="12" md="6" class="order-first order-md-0 pt-3 pt-md-7">
        <div
          class="footer-page-links d-flex flex-column align-center align-md-start mb-5 mb-md-0"
        >
          <nuxt-link
            v-for="item in navItems"
            :key="item[0]"
            :to="localePath(item[0])"
            class="nav-menu-item mb-3"
          >
            {{ item[1] }}
          </nuxt-link>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="d-flex flex-column align-center d-md-inline pt-3 pt-md-7"
      >
        <a
          class="footer-external-link"
          href="https://www.ssg.coop/"
          target="_blank"
        >
          <v-img
            src="/images/ssg-logo@2x.png"
            alt="Sustainability Solutions Group Logo"
            width="165"
            class="mb-3 footer-logo-image"
          ></v-img
        ></a>
        <a
          class="footer-external-link"
          href="https://www.ssg.coop/"
          target="_blank"
          >Developed by SSG</a
        >
      </v-col>
    </v-row>
  </v-footer>
</template>
<script setup lang="ts">
const localePath = useLocalePath();
const localeRoute = useLocaleRoute();
const { t, locale } = useI18n();

// get ordered list of navigation items in current locale to show in header
const getNavItems = () => {
  return [
    // paths
    "about",
    "contact",
  ].map((item) => [
    item,
    // lookup translations/titles for each path from locale files
    t(`navigation.routeTitles.${item}`),
  ]);
};
const navItems = ref(getNavItems());

// watch for changes to locale
watch(
  () => locale.value,
  () => {
    navItems.value = getNavItems();
  },
  { immediate: true }
);

// navigate to a page
const navigate = (name: string) => {
  const route = localeRoute({ name: name });
  if (route) {
    return navigateTo(route.fullPath);
  }
};
</script>
<style lang="scss">
.footer-external-link {
  color: #ffffff;
}
.footer-page-links a {
  color: #f1d76a !important;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.409;
}
.footer-page-links a.router-link-active,
.footer-page-links a:hover {
  color: #ffffff !important;
}
</style>
