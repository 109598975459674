// caseStudies slugs i18n cross-references
// this file was generated by the ch4pathways-org-website-infrastructure/data_processing/processors.py generateCaseStudiesI18nSlugs() function in ch4pathways-org-website-infrastructure repository
// generated on 2023-09-20

export default function caseStudiesI18nSlugsConfig() {
const { t } = useI18n();

const caseStudiesI18nSlugs = [{"id": 11, "slugs": {"en": "Brazil-catadores-wastepicker-climate-methane-recycle"}}, {"id": 2, "slugs": {"en": "financial-sustainability-waste-methane-Argentina", "pt": "Sustentabilidade-financeira-residuos-metano-Argentina", "es": "Sostenibilidad-financiera-residuos-metano-Argentina"}}, {"id": 1, "slugs": {"en": "composting-la-pintana-chile-organic-waste", "pt": "compostagem-la-pintana-chile-residuos-organicos", "es": "composting-la-pintana-chile-residuos-organicos"}}];

return caseStudiesI18nSlugs;
}