<template>
  <div>
    <v-app-bar app flat>
      <v-row no-gutters class="align-center justify-space-between">
        <v-col class="header-logo-container">
          <NuxtLink :to="localePath('/')">
            <v-img
              src="/images/ch4pathways-logo.png"
              alt="CH4Pathways.org"
            ></v-img>
          </NuxtLink>
        </v-col>
        <v-col class="text-right pl-5 main-menu-container">
          <v-row no-gutters class="pr-4">
            <v-col cols="12" align-self="end">
              <div
                id="locale-switcher"
                class="text-right d-none d-lg-block locale-switcher"
              >
                <locale-switcher class="mt-n1 mb-0 pb-0"></locale-switcher>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="main-menu-wrapper main-menu-desktop d-none d-lg-block"
            >
              <nuxt-link
                v-for="item in navItems"
                :key="item[0]"
                class="mr-5 header-menu-item nav-menu-item"
                :to="localePath(item[0])"
              >
                {{ item[1] }}
              </nuxt-link>
            </v-col>
            <v-col
              cols="12"
              class="main-menu-wrapper main-menu-mobile d-flex flex-column align-end d-lg-none"
            >
              <v-icon icon="$bars" @click.stop="drawer = !drawer"></v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" location="right" temporary>
      <v-icon
        icon="$times"
        class="drawer-close-btn mb-10"
        @click.stop="drawer = !drawer"
      ></v-icon>
      <nuxt-link
        v-for="item in navItems"
        :key="item[0]"
        class="mb-5 header-menu-item nav-menu-item"
        :to="localePath(item[0])"
      >
        {{ item[1] }}
      </nuxt-link>
      <div class="mt-5 locale-switcher locale-switcher-mobile">
        <locale-switcher class="mt-n1 mb-0 pb-0"></locale-switcher>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script setup lang="ts">
const localePath = useLocalePath();
const localeRoute = useLocaleRoute();
const { t, locale } = useI18n();

// get ordered list of navigation items in current locale to show in header
const getNavItems = () => {
  return [
    // paths
    "explore",
    "methane101",
    "solutions",
    "for_policy_makers",
  ].map((item) => [
    // explore page has special params
    item === "explore"
      ? {
          name: "explore-region-country-sector",
          params: { region: "SA", country: t("explorer.all") },
        }
      : item === "solutions"
      ? { name: "solutions_overview-sector" }
      : item,
    // lookup translations/titles for each path from locale files
    t(`navigation.routeTitles.${item}`),
  ]);
};
const navItems = ref(getNavItems());

// watch for changes to locale
watch(
  () => locale.value,
  () => {
    navItems.value = getNavItems();
  },
  { immediate: true }
);

// navigate to a page
const navigate = (name: string) => {
  const route = localeRoute({ name: name });
  if (route) {
    return navigateTo(route.fullPath);
  }
};
// Nav Drawer
import { ref, watch } from "vue";

const drawer = ref(false);
const group = ref(null);

watch(group, () => {
  drawer.value = false;
});

// Header Scroll Animation
if (process.client) {
  // only perform on client (window, document not available to ssr process)
  document.addEventListener("scroll", () => {
    if (document.documentElement.scrollTop > 12) {
      const header = document.querySelector("header");
      if (header) header.classList.add("active");
    } else {
      const header = document.querySelector("header");
      if (header) header.classList.remove("active");
    }
  });
}
</script>

<style lang="scss">
header.v-toolbar {
  background-color: #e8f7f4 !important;
  padding: 10px 20px;

  @media (min-width: 1025px) {
    padding: 20px;
    min-height: 155px;
  }
}
.v-toolbar__content {
  max-width: 1140px;
  margin: auto;
}
.header-logo-container {
  max-width: 285px !important;
}
.main-menu-container {
  @media (max-width: 1219px) {
    max-width: 40%;
  }
}
// Menu Items
.header-menu-item {
  padding: 0;
  color: #050505;
  background-color: transparent;
  font-family: "Barlow";
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
}
.header-menu-item.router-link-active,
.header-menu-item:hover {
  // using text-shadow instead of bold to prevent text shifting on hover
  color: #33a390;
  text-shadow: 1px 0px 0px;
}
// Switcher
.locale-switcher {
  max-width: 175px !important;
  float: right !important;
  max-height: 3em !important;
  margin-bottom: 15px;
}
.locale-switcher .v-icon,
.locale-switcher .v-field__input {
  color: #595959 !important;
}
.locale-switcher .v-field__input {
  padding: 1rem 0 0 0 !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
}
.v-navigation-drawer {
  top: 0px !important;
  width: 80% !important;
  height: 100% !important;
  padding: 30px 15px 30px 20px;
  z-index: 99999 !important;
}
.drawer-close-btn {
  margin-left: auto;
}
.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
.locale-switcher-mobile {
  margin: 80px auto 0 !important;
}
.v-overlay--active {
  z-index: 100000 !important;
}
.v-overlay--active .v-list {
  padding: 0;
}
.v-overlay--active .v-list-item:hover .v-list-item__content .v-list-item-title {
  color: #33a28f !important;
}
.v-overlay--active .v-list-item__overlay {
  opacity: 0 !important;
}
.v-list-item__content .v-list-item-title {
  font-family: "Barlow";
  font-weight: 500 !important;
  text-transform: uppercase;
}
.v-list-item--density-default.v-list-item--one-line {
  min-height: 1em;
  padding-top: 6px;
  padding-bottom: 6px;
}

// Header Scroll Animation Style
header.active {
  box-shadow: 0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0px 1px 10px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}
</style>
