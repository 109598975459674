import { default as indexLy2WuyvRUYMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/about/index.vue?macro=true";
import { default as _91CaseStudyURL_93xd30qKpj6KMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/casestudy/[CaseStudyURL].vue?macro=true";
import { default as indexa8nal8iQ53Meta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/contact/index.vue?macro=true";
import { default as _91PageURL_93pjEmvmK5vHMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/content/[PageURL].vue?macro=true";
import { default as _91_91sector_93_93Xz4SncBgaKMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/explore/[region]/[country]/[[sector]].vue?macro=true";
import { default as indexAIIKJGLpkEMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/for_policy_makers/index.vue?macro=true";
import { default as indexKcSKRkvqesMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/index.vue?macro=true";
import { default as indexDo1pYco3irMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/methane101/index.vue?macro=true";
import { default as _91PageURL_93TDJdf7EwzSMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/sector/[PageURL].vue?macro=true";
import { default as _91_91sector_93_93qzdre7tAHrMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions_overview/[[sector]].vue?macro=true";
import { default as _91SolutionURL_932dEaO5EROsMeta } from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions/[SolutionURL].vue?macro=true";
export default [
  {
    name: indexLy2WuyvRUYMeta?.name ?? "about___en",
    path: indexLy2WuyvRUYMeta?.path ?? "/en/about",
    meta: indexLy2WuyvRUYMeta || {},
    alias: indexLy2WuyvRUYMeta?.alias || [],
    redirect: indexLy2WuyvRUYMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexLy2WuyvRUYMeta?.name ?? "about___es___default",
    path: indexLy2WuyvRUYMeta?.path ?? "/sobre",
    meta: indexLy2WuyvRUYMeta || {},
    alias: indexLy2WuyvRUYMeta?.alias || [],
    redirect: indexLy2WuyvRUYMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexLy2WuyvRUYMeta?.name ?? "about___es",
    path: indexLy2WuyvRUYMeta?.path ?? "/es/sobre",
    meta: indexLy2WuyvRUYMeta || {},
    alias: indexLy2WuyvRUYMeta?.alias || [],
    redirect: indexLy2WuyvRUYMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexLy2WuyvRUYMeta?.name ?? "about___pt",
    path: indexLy2WuyvRUYMeta?.path ?? "/pt/sobre",
    meta: indexLy2WuyvRUYMeta || {},
    alias: indexLy2WuyvRUYMeta?.alias || [],
    redirect: indexLy2WuyvRUYMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91CaseStudyURL_93xd30qKpj6KMeta?.name ?? "casestudy-CaseStudyURL___en",
    path: _91CaseStudyURL_93xd30qKpj6KMeta?.path ?? "/en/case_study/:CaseStudyURL",
    meta: _91CaseStudyURL_93xd30qKpj6KMeta || {},
    alias: _91CaseStudyURL_93xd30qKpj6KMeta?.alias || [],
    redirect: _91CaseStudyURL_93xd30qKpj6KMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/casestudy/[CaseStudyURL].vue").then(m => m.default || m)
  },
  {
    name: _91CaseStudyURL_93xd30qKpj6KMeta?.name ?? "casestudy-CaseStudyURL___es___default",
    path: _91CaseStudyURL_93xd30qKpj6KMeta?.path ?? "/caso_de_estudio/:CaseStudyURL",
    meta: _91CaseStudyURL_93xd30qKpj6KMeta || {},
    alias: _91CaseStudyURL_93xd30qKpj6KMeta?.alias || [],
    redirect: _91CaseStudyURL_93xd30qKpj6KMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/casestudy/[CaseStudyURL].vue").then(m => m.default || m)
  },
  {
    name: _91CaseStudyURL_93xd30qKpj6KMeta?.name ?? "casestudy-CaseStudyURL___es",
    path: _91CaseStudyURL_93xd30qKpj6KMeta?.path ?? "/es/caso_de_estudio/:CaseStudyURL",
    meta: _91CaseStudyURL_93xd30qKpj6KMeta || {},
    alias: _91CaseStudyURL_93xd30qKpj6KMeta?.alias || [],
    redirect: _91CaseStudyURL_93xd30qKpj6KMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/casestudy/[CaseStudyURL].vue").then(m => m.default || m)
  },
  {
    name: _91CaseStudyURL_93xd30qKpj6KMeta?.name ?? "casestudy-CaseStudyURL___pt",
    path: _91CaseStudyURL_93xd30qKpj6KMeta?.path ?? "/pt/estudo_de_caso/:CaseStudyURL",
    meta: _91CaseStudyURL_93xd30qKpj6KMeta || {},
    alias: _91CaseStudyURL_93xd30qKpj6KMeta?.alias || [],
    redirect: _91CaseStudyURL_93xd30qKpj6KMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/casestudy/[CaseStudyURL].vue").then(m => m.default || m)
  },
  {
    name: indexa8nal8iQ53Meta?.name ?? "contact___en",
    path: indexa8nal8iQ53Meta?.path ?? "/en/contact",
    meta: indexa8nal8iQ53Meta || {},
    alias: indexa8nal8iQ53Meta?.alias || [],
    redirect: indexa8nal8iQ53Meta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexa8nal8iQ53Meta?.name ?? "contact___es___default",
    path: indexa8nal8iQ53Meta?.path ?? "/contacto",
    meta: indexa8nal8iQ53Meta || {},
    alias: indexa8nal8iQ53Meta?.alias || [],
    redirect: indexa8nal8iQ53Meta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexa8nal8iQ53Meta?.name ?? "contact___es",
    path: indexa8nal8iQ53Meta?.path ?? "/es/contacto",
    meta: indexa8nal8iQ53Meta || {},
    alias: indexa8nal8iQ53Meta?.alias || [],
    redirect: indexa8nal8iQ53Meta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexa8nal8iQ53Meta?.name ?? "contact___pt",
    path: indexa8nal8iQ53Meta?.path ?? "/pt/contato",
    meta: indexa8nal8iQ53Meta || {},
    alias: indexa8nal8iQ53Meta?.alias || [],
    redirect: indexa8nal8iQ53Meta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: _91PageURL_93pjEmvmK5vHMeta?.name ?? "content-PageURL___en",
    path: _91PageURL_93pjEmvmK5vHMeta?.path ?? "/en/content/:PageURL",
    meta: _91PageURL_93pjEmvmK5vHMeta || {},
    alias: _91PageURL_93pjEmvmK5vHMeta?.alias || [],
    redirect: _91PageURL_93pjEmvmK5vHMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/content/[PageURL].vue").then(m => m.default || m)
  },
  {
    name: _91PageURL_93pjEmvmK5vHMeta?.name ?? "content-PageURL___es___default",
    path: _91PageURL_93pjEmvmK5vHMeta?.path ?? "/contenido/:PageURL",
    meta: _91PageURL_93pjEmvmK5vHMeta || {},
    alias: _91PageURL_93pjEmvmK5vHMeta?.alias || [],
    redirect: _91PageURL_93pjEmvmK5vHMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/content/[PageURL].vue").then(m => m.default || m)
  },
  {
    name: _91PageURL_93pjEmvmK5vHMeta?.name ?? "content-PageURL___es",
    path: _91PageURL_93pjEmvmK5vHMeta?.path ?? "/es/contenido/:PageURL",
    meta: _91PageURL_93pjEmvmK5vHMeta || {},
    alias: _91PageURL_93pjEmvmK5vHMeta?.alias || [],
    redirect: _91PageURL_93pjEmvmK5vHMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/content/[PageURL].vue").then(m => m.default || m)
  },
  {
    name: _91PageURL_93pjEmvmK5vHMeta?.name ?? "content-PageURL___pt",
    path: _91PageURL_93pjEmvmK5vHMeta?.path ?? "/pt/conteudo/:PageURL",
    meta: _91PageURL_93pjEmvmK5vHMeta || {},
    alias: _91PageURL_93pjEmvmK5vHMeta?.alias || [],
    redirect: _91PageURL_93pjEmvmK5vHMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/content/[PageURL].vue").then(m => m.default || m)
  },
  {
    name: _91_91sector_93_93Xz4SncBgaKMeta?.name ?? "explore-region-country-sector___en",
    path: _91_91sector_93_93Xz4SncBgaKMeta?.path ?? "/en/explore/:region/:country/:sector?",
    meta: _91_91sector_93_93Xz4SncBgaKMeta || {},
    alias: _91_91sector_93_93Xz4SncBgaKMeta?.alias || [],
    redirect: _91_91sector_93_93Xz4SncBgaKMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/explore/[region]/[country]/[[sector]].vue").then(m => m.default || m)
  },
  {
    name: _91_91sector_93_93Xz4SncBgaKMeta?.name ?? "explore-region-country-sector___es___default",
    path: _91_91sector_93_93Xz4SncBgaKMeta?.path ?? "/explorar/:region/:country/:sector?",
    meta: _91_91sector_93_93Xz4SncBgaKMeta || {},
    alias: _91_91sector_93_93Xz4SncBgaKMeta?.alias || [],
    redirect: _91_91sector_93_93Xz4SncBgaKMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/explore/[region]/[country]/[[sector]].vue").then(m => m.default || m)
  },
  {
    name: _91_91sector_93_93Xz4SncBgaKMeta?.name ?? "explore-region-country-sector___es",
    path: _91_91sector_93_93Xz4SncBgaKMeta?.path ?? "/es/explorar/:region/:country/:sector?",
    meta: _91_91sector_93_93Xz4SncBgaKMeta || {},
    alias: _91_91sector_93_93Xz4SncBgaKMeta?.alias || [],
    redirect: _91_91sector_93_93Xz4SncBgaKMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/explore/[region]/[country]/[[sector]].vue").then(m => m.default || m)
  },
  {
    name: _91_91sector_93_93Xz4SncBgaKMeta?.name ?? "explore-region-country-sector___pt",
    path: _91_91sector_93_93Xz4SncBgaKMeta?.path ?? "/pt/explorar/:region/:country/:sector?",
    meta: _91_91sector_93_93Xz4SncBgaKMeta || {},
    alias: _91_91sector_93_93Xz4SncBgaKMeta?.alias || [],
    redirect: _91_91sector_93_93Xz4SncBgaKMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/explore/[region]/[country]/[[sector]].vue").then(m => m.default || m)
  },
  {
    name: indexAIIKJGLpkEMeta?.name ?? "for_policy_makers___en",
    path: indexAIIKJGLpkEMeta?.path ?? "/en/for_policy_makers",
    meta: indexAIIKJGLpkEMeta || {},
    alias: indexAIIKJGLpkEMeta?.alias || [],
    redirect: indexAIIKJGLpkEMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/for_policy_makers/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIIKJGLpkEMeta?.name ?? "for_policy_makers___es___default",
    path: indexAIIKJGLpkEMeta?.path ?? "/para_los_responsables_politicos",
    meta: indexAIIKJGLpkEMeta || {},
    alias: indexAIIKJGLpkEMeta?.alias || [],
    redirect: indexAIIKJGLpkEMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/for_policy_makers/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIIKJGLpkEMeta?.name ?? "for_policy_makers___es",
    path: indexAIIKJGLpkEMeta?.path ?? "/es/para_los_responsables_politicos",
    meta: indexAIIKJGLpkEMeta || {},
    alias: indexAIIKJGLpkEMeta?.alias || [],
    redirect: indexAIIKJGLpkEMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/for_policy_makers/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIIKJGLpkEMeta?.name ?? "for_policy_makers___pt",
    path: indexAIIKJGLpkEMeta?.path ?? "/pt/para_os_decisores_politicos",
    meta: indexAIIKJGLpkEMeta || {},
    alias: indexAIIKJGLpkEMeta?.alias || [],
    redirect: indexAIIKJGLpkEMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/for_policy_makers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKcSKRkvqesMeta?.name ?? "index___en",
    path: indexKcSKRkvqesMeta?.path ?? "/en",
    meta: indexKcSKRkvqesMeta || {},
    alias: indexKcSKRkvqesMeta?.alias || [],
    redirect: indexKcSKRkvqesMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKcSKRkvqesMeta?.name ?? "index___es___default",
    path: indexKcSKRkvqesMeta?.path ?? "/",
    meta: indexKcSKRkvqesMeta || {},
    alias: indexKcSKRkvqesMeta?.alias || [],
    redirect: indexKcSKRkvqesMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKcSKRkvqesMeta?.name ?? "index___es",
    path: indexKcSKRkvqesMeta?.path ?? "/es",
    meta: indexKcSKRkvqesMeta || {},
    alias: indexKcSKRkvqesMeta?.alias || [],
    redirect: indexKcSKRkvqesMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKcSKRkvqesMeta?.name ?? "index___pt",
    path: indexKcSKRkvqesMeta?.path ?? "/pt",
    meta: indexKcSKRkvqesMeta || {},
    alias: indexKcSKRkvqesMeta?.alias || [],
    redirect: indexKcSKRkvqesMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexDo1pYco3irMeta?.name ?? "methane101___en",
    path: indexDo1pYco3irMeta?.path ?? "/en/methane101",
    meta: indexDo1pYco3irMeta || {},
    alias: indexDo1pYco3irMeta?.alias || [],
    redirect: indexDo1pYco3irMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/methane101/index.vue").then(m => m.default || m)
  },
  {
    name: indexDo1pYco3irMeta?.name ?? "methane101___es___default",
    path: indexDo1pYco3irMeta?.path ?? "/metano",
    meta: indexDo1pYco3irMeta || {},
    alias: indexDo1pYco3irMeta?.alias || [],
    redirect: indexDo1pYco3irMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/methane101/index.vue").then(m => m.default || m)
  },
  {
    name: indexDo1pYco3irMeta?.name ?? "methane101___es",
    path: indexDo1pYco3irMeta?.path ?? "/es/metano",
    meta: indexDo1pYco3irMeta || {},
    alias: indexDo1pYco3irMeta?.alias || [],
    redirect: indexDo1pYco3irMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/methane101/index.vue").then(m => m.default || m)
  },
  {
    name: indexDo1pYco3irMeta?.name ?? "methane101___pt",
    path: indexDo1pYco3irMeta?.path ?? "/pt/metano",
    meta: indexDo1pYco3irMeta || {},
    alias: indexDo1pYco3irMeta?.alias || [],
    redirect: indexDo1pYco3irMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/methane101/index.vue").then(m => m.default || m)
  },
  {
    name: _91PageURL_93TDJdf7EwzSMeta?.name ?? "sector-PageURL___en",
    path: _91PageURL_93TDJdf7EwzSMeta?.path ?? "/en/sector/:PageURL",
    meta: _91PageURL_93TDJdf7EwzSMeta || {},
    alias: _91PageURL_93TDJdf7EwzSMeta?.alias || [],
    redirect: _91PageURL_93TDJdf7EwzSMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/sector/[PageURL].vue").then(m => m.default || m)
  },
  {
    name: _91PageURL_93TDJdf7EwzSMeta?.name ?? "sector-PageURL___es___default",
    path: _91PageURL_93TDJdf7EwzSMeta?.path ?? "/sector/:PageURL",
    meta: _91PageURL_93TDJdf7EwzSMeta || {},
    alias: _91PageURL_93TDJdf7EwzSMeta?.alias || [],
    redirect: _91PageURL_93TDJdf7EwzSMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/sector/[PageURL].vue").then(m => m.default || m)
  },
  {
    name: _91PageURL_93TDJdf7EwzSMeta?.name ?? "sector-PageURL___es",
    path: _91PageURL_93TDJdf7EwzSMeta?.path ?? "/es/sector/:PageURL",
    meta: _91PageURL_93TDJdf7EwzSMeta || {},
    alias: _91PageURL_93TDJdf7EwzSMeta?.alias || [],
    redirect: _91PageURL_93TDJdf7EwzSMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/sector/[PageURL].vue").then(m => m.default || m)
  },
  {
    name: _91PageURL_93TDJdf7EwzSMeta?.name ?? "sector-PageURL___pt",
    path: _91PageURL_93TDJdf7EwzSMeta?.path ?? "/pt/setor/:PageURL",
    meta: _91PageURL_93TDJdf7EwzSMeta || {},
    alias: _91PageURL_93TDJdf7EwzSMeta?.alias || [],
    redirect: _91PageURL_93TDJdf7EwzSMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/sector/[PageURL].vue").then(m => m.default || m)
  },
  {
    name: _91_91sector_93_93qzdre7tAHrMeta?.name ?? "solutions_overview-sector___en",
    path: _91_91sector_93_93qzdre7tAHrMeta?.path ?? "/en/solutions_overview/:sector?",
    meta: _91_91sector_93_93qzdre7tAHrMeta || {},
    alias: _91_91sector_93_93qzdre7tAHrMeta?.alias || [],
    redirect: _91_91sector_93_93qzdre7tAHrMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions_overview/[[sector]].vue").then(m => m.default || m)
  },
  {
    name: _91_91sector_93_93qzdre7tAHrMeta?.name ?? "solutions_overview-sector___es___default",
    path: _91_91sector_93_93qzdre7tAHrMeta?.path ?? "/descripcion_soluciones/:sector?",
    meta: _91_91sector_93_93qzdre7tAHrMeta || {},
    alias: _91_91sector_93_93qzdre7tAHrMeta?.alias || [],
    redirect: _91_91sector_93_93qzdre7tAHrMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions_overview/[[sector]].vue").then(m => m.default || m)
  },
  {
    name: _91_91sector_93_93qzdre7tAHrMeta?.name ?? "solutions_overview-sector___es",
    path: _91_91sector_93_93qzdre7tAHrMeta?.path ?? "/es/descripcion_soluciones/:sector?",
    meta: _91_91sector_93_93qzdre7tAHrMeta || {},
    alias: _91_91sector_93_93qzdre7tAHrMeta?.alias || [],
    redirect: _91_91sector_93_93qzdre7tAHrMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions_overview/[[sector]].vue").then(m => m.default || m)
  },
  {
    name: _91_91sector_93_93qzdre7tAHrMeta?.name ?? "solutions_overview-sector___pt",
    path: _91_91sector_93_93qzdre7tAHrMeta?.path ?? "/pt/visao_geral_solucoes/:sector?",
    meta: _91_91sector_93_93qzdre7tAHrMeta || {},
    alias: _91_91sector_93_93qzdre7tAHrMeta?.alias || [],
    redirect: _91_91sector_93_93qzdre7tAHrMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions_overview/[[sector]].vue").then(m => m.default || m)
  },
  {
    name: _91SolutionURL_932dEaO5EROsMeta?.name ?? "solutions-SolutionURL___en",
    path: _91SolutionURL_932dEaO5EROsMeta?.path ?? "/en/solutions/:SolutionURL",
    meta: _91SolutionURL_932dEaO5EROsMeta || {},
    alias: _91SolutionURL_932dEaO5EROsMeta?.alias || [],
    redirect: _91SolutionURL_932dEaO5EROsMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions/[SolutionURL].vue").then(m => m.default || m)
  },
  {
    name: _91SolutionURL_932dEaO5EROsMeta?.name ?? "solutions-SolutionURL___es___default",
    path: _91SolutionURL_932dEaO5EROsMeta?.path ?? "/soluciones/:SolutionURL",
    meta: _91SolutionURL_932dEaO5EROsMeta || {},
    alias: _91SolutionURL_932dEaO5EROsMeta?.alias || [],
    redirect: _91SolutionURL_932dEaO5EROsMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions/[SolutionURL].vue").then(m => m.default || m)
  },
  {
    name: _91SolutionURL_932dEaO5EROsMeta?.name ?? "solutions-SolutionURL___es",
    path: _91SolutionURL_932dEaO5EROsMeta?.path ?? "/es/soluciones/:SolutionURL",
    meta: _91SolutionURL_932dEaO5EROsMeta || {},
    alias: _91SolutionURL_932dEaO5EROsMeta?.alias || [],
    redirect: _91SolutionURL_932dEaO5EROsMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions/[SolutionURL].vue").then(m => m.default || m)
  },
  {
    name: _91SolutionURL_932dEaO5EROsMeta?.name ?? "solutions-SolutionURL___pt",
    path: _91SolutionURL_932dEaO5EROsMeta?.path ?? "/pt/solucoes/:SolutionURL",
    meta: _91SolutionURL_932dEaO5EROsMeta || {},
    alias: _91SolutionURL_932dEaO5EROsMeta?.alias || [],
    redirect: _91SolutionURL_932dEaO5EROsMeta?.redirect || undefined,
    component: () => import("/Users/noah/Documents/websites/ch4pathways-org-website-frontend/pages/solutions/[SolutionURL].vue").then(m => m.default || m)
  }
]