// sector meta info
export default function sectorsConfig() {
  const { t } = useI18n();

  const sectors = {
    agriculture: {
      id: "agriculture",
      name: t("explorer.sectors.agriculture.name"),
      slug: t("explorer.sectors.agriculture.slug"),
      icon: "cowRegular",
      iconAlt: "cow",
      color: "#33a390",
      colorAccent: "#267a6c",
      colorScale: [
        "#E8F7F4",
        "#94cdc2",
        "#68b8a9",
        "#33A390",
        "#288d84",
        "#247775",
        "#0f5453",
      ],
      solutionsColors: [
        "#E8F7F4",
        "#94cdc2",
        "#33A390",
        "#288d84",
        "#247775",
        "#0f5453",
      ],
      solutions: {
        agDigestManureDairy: {
          id: "agDigestManureDairy",
          name: t("explorer.sectors.agriculture.solutions.agDigestManureDairy.name"),
          altId: "agDigestManure",
        },
        agDigestManureSwine: {
          id: "agDigestManureSwine",
          name: t("explorer.sectors.agriculture.solutions.agDigestManureSwine.name"),
          altId: "agDigestManure",
        },
        agMethaneInhibit: {
          id: "agMethaneInhibit",
          name: t("explorer.sectors.agriculture.solutions.agMethaneInhibit.name"),
        },
        agChangeDiets: {
          id: "agChangeDiets",
          name: t("explorer.sectors.agriculture.solutions.agChangeDiets.name"),
        },
        agRice: {
          id: "agRice",
          name: t("explorer.sectors.agriculture.solutions.agRice.name"),
        },
        agFoodLoss: {
          id: "agFoodLoss",
          name: t("explorer.sectors.agriculture.solutions.agFoodLoss.name"),
        },
      },
    },
    energy: {
      id: "energy",
      name: t("explorer.sectors.energy.name"),
      slug: t("explorer.sectors.energy.slug"),
      icon: "industryWindowsRegular",
      iconAlt: "industryWindows",
      color: "#f2d86a",
      colorAccent: "#b6a250",
      colorScale: [
        "#f1dd88",
        "#f2da78",
        "#f2d668",
        "#f3d356",
        "#f4cf43",
        "#f5cb2c",
        "#f6c700",
      ],
      solutionsColors: [
        "#f1dd88",
        "#f3d356",
        "#f6c700",
      ],
      solutions: {
        enReduceFugitivesOG: {
          id: "enReduceFugitivesOG",
          name: t("explorer.sectors.energy.solutions.enReduceFugitivesOG.name"),
          altId: "enReduceFugitives",
        },
        enReduceFugitivesCoal: {
          id: "enReduceFugitivesCoal",
          name: t("explorer.sectors.energy.solutions.enReduceFugitivesCoal.name"),
          altId: "enReduceFugitives",
        },
        enDecarbonization: {
          id: "enDecarbonization",
          name: t("explorer.sectors.energy.solutions.enDecarbonization.name"),
        },
      },
    },
    waste: {
      id: "waste",
      name: t("explorer.sectors.waste.name"),
      slug: t("explorer.sectors.waste.slug"),
      icon: "appleCoreRegular",
      iconAlt: "appleCore",
      color: "#c94b3b",
      colorAccent: "#994035",
      colorScale: [
        "#e28a7f",
        "#d1786c",
        "#c16659",
        "#c16659",
        "#9f4234",
        "#8d3123",
        "#7c1e12",
      ],
      solutionsColors: [
        "#e28a7f",
        "#d1786c",
        "#c16659",
        "#9f4234",
        "#7c1e12",
      ],
      solutions: {
        waLfgCapture: {
          id: "waLfgCapture",
          name: t("explorer.sectors.waste.solutions.waLfgCapture.name"),
        },
        waComposting: {
          id: "waComposting",
          name: t("explorer.sectors.waste.solutions.waComposting.name"),
        },
        waDigestion: {
          id: "waDigestion",
          name: t("explorer.sectors.waste.solutions.waDigestion.name"),
          altId: "agDigestManure",
        },
        waRecycling: {
          id: "waRecycling",
          name: t("explorer.sectors.waste.solutions.waRecycling.name"),
        },
        waAvoidWaste: {
          id: "waAvoidWaste",
          name: t("explorer.sectors.waste.solutions.waAvoidWaste.name"),
          altId: "agFoodLoss",
        },
      },
    },
  };

  return sectors;
}
