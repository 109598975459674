<template>
  <div>
    <v-app>
      <NuxtLayout>
        <HeaderMain></HeaderMain>
        <div class="default-layout-container">
          <div class="content-container-1140">
            <div class="main-content">
              <div class="page-content">
                <template v-if="error.statusCode === 404">
                  <h1>{{ t('errors.404.title') }}</h1>
                  <div v-html="md.render(text404)"></div>
                </template>
                <template v-else>
                  <h1>{{ t('errors.general.title') }}</h1>
                <div v-html="md.render(textGeneral)"></div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <FooterMain></FooterMain>
      </NuxtLayout>
    </v-app>
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const { md } = useMarkdownIt();
const text404 = t('errors.404.textMD');
const textGeneral = t('errors.general.textMD');
const error = useError();
</script>
<style lang="scss" scoped></style>
