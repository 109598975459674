// config and data for pathways explorer tool
import regionsConfig from "@/config/pathwaysConfigRegions";
import sectorsConfig from "@/config/pathwaysConfigSectors";
import scenariosConfig from "@/config/pathwaysConfigScenarios";
import mitigationPathwaysConfig from "@/config/pathwaysConfigPathways";
import socialCaseTypesConfig from "@/config/pathwaysConfigSocialCaseTypes";

export default function usePathwaysConfig() {
  const { t } = useI18n();
  const startYear = 2020; // the earliest historic year in the tool
  const endYear = 2050; // the last year of simulation
  const currentYear = 2022; // the most current year with empirical data
  const budgetStartYear = 2021; // the start year for methane budgets/pathways
  const commitmentYear = 2030; // intermediate year where commitments are linked to
  const budgets = mitigationPathwaysConfig();
  const budgetsArray = genericUtils().convertObjectToArray(budgets);
  const scenarios = scenariosConfig();
  const regions = regionsConfig();
  const regionsArray = genericUtils().convertObjectToArray(regions);
  const regionCodes = regionsArray.map((_region) => _region.id);
  const countryCodes = regionCodes.filter((region) => region !== 'SA'); // country codes only (no all of SA)
  const sectors = sectorsConfig();
  const sectorsArray = genericUtils().convertObjectToArray(sectors);
  const sectorCodes = sectorsArray.map((_sector) => _sector.id);
  const sectorLocaleSlugs = [t("explorer.sectors.all.slug"), ...sectorsArray.map((_sector) => t(`explorer.sectors.${_sector.id}.slug`))]; // i18n slugs for all sectors plus 'all'
  const sectorLocaleXRefs = sectorsArray.reduce((acc, _sector) => (acc[t(`explorer.sectors.${_sector.id}.slug`)] = _sector.id, acc), {}); // cross-reference i18n slugs with config sector ids
  const socialCaseTypes = socialCaseTypesConfig();
  const socialCaseTypesArray = genericUtils().convertObjectToArray(socialCaseTypes);

  /**
   * returns an array of solutions for a given sector id
  */
  const getSolutionsForSector = ((sectorId) => {
    return genericUtils().convertObjectToArray(sectors[sectorId].solutions);
  });

  return {
    startYear,
    endYear,
    currentYear,
    budgetStartYear,
    commitmentYear,
    regions,
    regionsArray,
    regionCodes,
    countryCodes,
    budgets,
    budgetsArray,
    scenarios,
    sectors,
    sectorsArray,
    sectorCodes,
    sectorLocaleSlugs,
    sectorLocaleXRefs,
    getSolutionsForSector,
    socialCaseTypes,
    socialCaseTypesArray,
  };
}
