// config and data for pathways explorer tool
import solutionsI18nSlugsConfig from "@/config/solutionsI18nSlugs";

export default function useSolutionsI18nConfig() {
  const solutionsSlugs = solutionsI18nSlugsConfig();

  function getSolutionXRefs(locale, slug) {
    const foundSolution = solutionsSlugs.find(solution => {
      const solutionSlug = solution.slugs[locale];
      return solutionSlug && solutionSlug === slug;
    });

    return foundSolution || null;
  }

  return {
    solutionsSlugs,
    getSolutionXRefs,
  };
}
