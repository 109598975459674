<template>
  <v-select
    v-model="language"
    variant="plain"
    :items="localesOptions"
    prepend-icon="$globe"
    menu-icon="$chevronDown"
  ></v-select>
</template>

<script setup lang="ts">
const { locales, locale, setLocale } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const localeRoute = useLocaleRoute();
const route = useRoute();
const { getSolutionXRefs } = useSolutionsI18nConfig();
const { getCaseStudiesXRefs } = useCaseStudiesI18nConfig();
const { sectorLocaleXRefs } = usePathwaysConfig();

const localesOptions = locales.value.map((_locale: any) => {
  return {
    title: _locale.name,
    value: _locale.code,
  };
});

const sectorsSlugsI18n = {
  // cross references for sector slugs
  // this could be DRYer, but this is fast and dirty
  agriculture: {
    en: "agriculture",
    es: "agricultura",
    pt: "agricultura",
  },
  energy: {
    en: "energy",
    es: "energia",
    pt: "energia",
  },
  waste: {
    en: "waste",
    es: "residuos",
    pt: "residuos",
  },
};

const language = computed({
  get: () => locale.value,
  set: (value) => {
    // check for paths with custom slugs and manually build routes
    if (route.name.startsWith("solutions-SolutionURL")) {
      // get the slug i18n x-refs
      const solution = getSolutionXRefs(locale.value, route.params.SolutionURL);
      const newRoute = localeRoute(
        {
          name: "solutions-SolutionURL",
          params: { SolutionURL: solution?.slugs[value] },
        },
        value
      );
      setLocale(value);
      navigateTo(newRoute, { external: true });
    } else if (route.name.startsWith("casestudy-CaseStudyURL")) {
      // get the slug i18n x-refs
      const casestudy = getCaseStudiesXRefs(locale.value, route.params.CaseStudyURL);
      const newRoute = localeRoute(
        {
          name: "casestudy-CaseStudyURL",
          params: { CaseStudyURL: casestudy?.slugs[value] },
        },
        value
      );
      setLocale(value);
      navigateTo(newRoute, { external: true });
    } else if (route.name.startsWith("sector-PageURL")) {
      setLocale(value);
      const newRoute = localeRoute(
        {
          name: "sector-PageURL",
          params: {
            PageURL:
              sectorsSlugsI18n[sectorLocaleXRefs[route.params.PageURL]][value],
          },
        },
        value
      );
      navigateTo(newRoute, { external: true });
    } else if (route.name.startsWith("solutions_overview-sector")) {
      setLocale(value);
      const params = {};
      if (route.params.sector !== '') {
        params.sector = sectorsSlugsI18n[sectorLocaleXRefs[route.params.sector]][value];
      }
      const newRoute = localeRoute(
        {
          name: "solutions_overview-sector",
          params: params,
        },
        value
      );
      navigateTo(newRoute, { external: true });
    } else {
      // no custom slugs
      setLocale(value);
      const newRoute = switchLocalePath(value);
      navigateTo(newRoute, { external: true });
    }
  },
});
</script>
<style lang="css" scoped>
.v-list-item {
  color: red;
}
</style>
