import revive_payload_client_4sVQNw7RlN from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import unhead_KgADcZ0jPj from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import chunk_reload_client_UciE0i6zes from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import i18n_yfWm7jX06p from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import composition_sLxaNGmlSL from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import vuetify_icons_RMzWu406ID from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_i18n_ftx86CUdf5 from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import components_plugin_KR1HBZs4kY from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/.nuxt/components.plugin.mjs";
import payload_client_yVLowv6hDl from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import errorHandler_cfkZ75xwSQ from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/plugins/errorHandler.js";
import fontAwesome_ZfwYhjRo2I from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/plugins/fontAwesome.js";
import vuetify_sync_XQ085wXJDC from "/Users/noah/Documents/websites/ch4pathways-org-website-frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-sync.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  unhead_KgADcZ0jPj,
  chunk_reload_client_UciE0i6zes,
  i18n_yfWm7jX06p,
  composition_sLxaNGmlSL,
  vuetify_icons_RMzWu406ID,
  vuetify_i18n_ftx86CUdf5,
  prefetch_client_5tzzN0oIVL,
  components_plugin_KR1HBZs4kY,
  payload_client_yVLowv6hDl,
  errorHandler_cfkZ75xwSQ,
  fontAwesome_ZfwYhjRo2I,
  vuetify_sync_XQ085wXJDC
]