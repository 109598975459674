// country and region meta info
export default function regionsConfig() {
  const { t } = useI18n();

  const regions = {
    // all available regions in the tool
    SA: {
      id: "SA",
      name: t("explorer.regions.SA.name"),
      hasCH4Pledge: false,
      population: [
        // population values for all years from startYear to endYear
        428856727,
        432461366,
        436042475,
        439904711,
        443651390,
        447234124,
        450654834,
        453918603,
        457038916,
        460025523,
        462882360,
        465609280,
        468204437,
        470678267,
        473036842,
        475279998,
        477407106,
        479416035,
        481306341,
        483080148,
        484737980,
        483415580,
        484742615,
        485956974,
        487060544,
        488053693,
        488936933,
        489712243,
        490378281,
        490932705,
        491375285,
      ],
      ch4budget: 1, // the proportion of the total methane budget for all of SA attributed to this country/region
    },
    AR: {
      id: "AR",
      name: t("explorer.regions.AR.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        45376763,
        45808747,
        46234830,
        46654581,
        47067641,
        47473760,
        47873268,
        48266524,
        48653385,
        49033678,
        49407265,
        49774276,
        50134861,
        50488930,
        50836373,
        51177087,
        51511042,
        51838245,
        52158610,
        52472054,
        52778477,
        50212929,
        50402787,
        50584827,
        50759160,
        50924787,
        51081914,
        51231274,
        51371596,
        51501321,
        51621174,
      ],
      ch4BudgetShare: 0.23,
    },
    BO: {
      id: "BO",
      name: t("explorer.regions.BO.name"),
      hasCH4Pledge: false,
      population: [
        // population values for all years from startYear to endYear
        11677406,
        11841955,
        12006031,
        12169501,
        12332252,
        12494181,
        12655174,
        12815140,
        12973939,
        13131492,
        13287672,
        13442367,
        13595512,
        13746998,
        13896755,
        14044703,
        14190792,
        14334944,
        14477103,
        14617203,
        14755212,
        14891077,
        15024732,
        15156151,
        15285287,
        15412105,
        15536564,
        15658626,
        15778250,
        15895385,
        16010008,
      ],
      ch4BudgetShare: 0.23,
    },
    BR: {
      id: "BR",
      name: t("explorer.regions.BR.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        211755692,
        213317639,
        214828540,
        216284269,
        217684462,
        219029093,
        220316530,
        221545234,
        222713669,
        223821305,
        224868462,
        225854415,
        226777802,
        227638581,
        228437122,
        229173685,
        229847832,
        230459062,
        231007871,
        231494650,
        231919922,
        232284233,
        232588580,
        232834065,
        233020996,
        233149625,
        233220257,
        233233670,
        233190482,
        233090464,
        232933276,
      ],
      ch4BudgetShare: 0.23,
    },
    CL: {
      id: "CL",
      name: t("explorer.regions.CL.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        19458310,
        19678363,
        19828563,
        19960889,
        20086377,
        20206953,
        20322807,
        20433791,
        20539666,
        20640230,
        20735289,
        20826397,
        20912278,
        20992831,
        21067996,
        21137769,
        21202223,
        21261462,
        21315630,
        21364889,
        21409418,
        21449428,
        21485105,
        21516611,
        21544064,
        21567513,
        21587015,
        21602589,
        21614271,
        21622101,
        21626079,
      ],
      ch4BudgetShare: 0.23,
    },
    CO: {
      id: "CO",
      name: t("explorer.regions.CO.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        50407647,
        51117378,
        51682692,
        52215503,
        52695952,
        53110609,
        53474637,
        53808013,
        54119568,
        54416612,
        54705567,
        54986438,
        55255685,
        55512668,
        55757459,
        55990158,
        56209177,
        56412923,
        56601485,
        56775073,
        56933436,
        57076544,
        57204837,
        57318606,
        57417736,
        57502165,
        57572263,
        57628427,
        57670653,
        57698970,
        57713467,
      ],
      ch4BudgetShare: 0.23,
    },
    EC: {
      id: "EC",
      name: t("explorer.regions.EC.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        17510643,
        17797737,
        18001000,
        18190484,
        18377366,
        18563370,
        18749869,
        18936525,
        19122047,
        19305626,
        19486952,
        19665584,
        19840908,
        20012290,
        20179297,
        20342202,
        20500994,
        20655436,
        20805884,
        20952371,
        21094529,
        21232395,
        21366555,
        21496458,
        21621378,
        21741483,
        21856853,
        21967446,
        22073004,
        22173750,
        22269779,
      ],
      ch4BudgetShare: 0.23,
    },
    // GF: {
    //   id: "GF",
    //   name: t("explorer.regions.GF.name"),
    //   hasCH4Pledge: true,
    //   population: [
    //     // population values for all years from startYear to endYear
    //     12345,
    //   ],
    //   ch4BudgetShare: 0.23,
    // },
    GY: {
      id: "GY",
      name: t("explorer.regions.GY.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        797202,
        804567,
        808726,
        813834,
        819594,
        825051,
        830213,
        835082,
        839668,
        843990,
        848032,
        851765,
        855198,
        858368,
        861278,
        863916,
        866301,
        868452,
        870380,
        872100,
        873641,
        874998,
        876161,
        877142,
        877955,
        878575,
        878991,
        879203,
        879236,
        879094,
        878746,
      ],
      ch4BudgetShare: 0.23,
    },
    PY: {
      id: "PY",
      name: t("explorer.regions.PY.name"),
      hasCH4Pledge: false,
      population: [
        // population values for all years from startYear to endYear
        6618695,
        6703799,
        6780744,
        6861524,
        6947270,
        7031341,
        7113876,
        7194655,
        7273521,
        7350460,
        7425448,
        7498454,
        7569644,
        7639257,
        7707286,
        7773694,
        7838570,
        7902181,
        7964226,
        8024670,
        8083610,
        8141028,
        8197012,
        8251538,
        8304646,
        8356299,
        8406438,
        8455070,
        8502116,
        8547722,
        8591486,
      ],
      ch4BudgetShare: 0.23,
    },
    PE: {
      id: "PE",
      name: t("explorer.regions.PE.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        32625948,
        33035304,
        33396698,
        33725844,
        34038457,
        34350244,
        34660114,
        34957600,
        35244330,
        35521943,
        35792079,
        36054888,
        36309290,
        36555034,
        36791909,
        37019661,
        37238190,
        37447651,
        37648215,
        37840036,
        38023290,
        38198236,
        38364756,
        38522478,
        38670990,
        38809920,
        38939517,
        39060054,
        39171129,
        39272354,
        39363351,
      ],
      ch4BudgetShare: 0.23,
    },
    SR: {
      id: "SR",
      name: t("explorer.regions.SR.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        607065,
        612985,
        618040,
        623236,
        628786,
        634221,
        639522,
        644698,
        649746,
        654678,
        659508,
        664205,
        668761,
        673182,
        677458,
        681584,
        685561,
        689374,
        693015,
        696508,
        699860,
        703054,
        706082,
        708977,
        711736,
        714358,
        716842,
        719188,
        721418,
        723527,
        725520,
      ],
      ch4BudgetShare: 0.23,
    },
    UY: {
      id: "UY",
      name: t("explorer.regions.UY.name"),
      hasCH4Pledge: true,
      population: [
        // population values for all years from startYear to endYear
        3530912,
        3543026,
        3554915,
        3566550,
        3577896,
        3588937,
        3599663,
        3610035,
        3620004,
        3629541,
        3638632,
        3647229,
        3655289,
        3662795,
        3669744,
        3676092,
        3681848,
        3687000,
        3691553,
        3695520,
        3698924,
        3701762,
        3704055,
        3705850,
        3707145,
        3707919,
        3708236,
        3708094,
        3707490,
        3706463,
        3705000,
      ],
      ch4BudgetShare: 0.23,
    },
    VE: {
      id: "VE",
      name: t("explorer.regions.VE.name"),
      hasCH4Pledge: false,
      population: [
        // population values for all years from startYear to endYear
        28490444,
        28199866,
        28301696,
        28838496,
        29395337,
        29926364,
        30419161,
        30871306,
        31289373,
        31675968,
        32027454,
        32343262,
        32629209,
        32897333,
        33154165,
        33399447,
        33634576,
        33859305,
        34072369,
        34275074,
        34467661,
        34649896,
        34821953,
        34984271,
        35139451,
        35288944,
        35432043,
        35568602,
        35698636,
        35821554,
        35937399,
      ],
      ch4BudgetShare: 0.23,
    },
  };

  return regions;
}
