<template>
  <div>
    <v-app>
      <NuxtLayout>
        <HeaderMain></HeaderMain>
        <NuxtPage />
        <FooterMain></FooterMain>
      </NuxtLayout>
    </v-app>
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - CH4 Pathways` : 'CH4 Pathways';
  }
})
</script>
<style lang="scss">
@use "./settings";

// Google Fonts - Barlow and Hind
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,500;1,800&family=Hind:wght@400;700&display=swap");

// Global General Settings
.v-application {
  font-family: "Barlow";
}
#page-container,
#__nuxt {
  margin-top: 84px;

  @media (min-width: 1025px) {
    margin-top: 135px;
  }
}
.content-container-1140 {
  width: 100%;
  max-width: 1140px;
  margin-left: auto !important;
  margin-right: auto !important;
}
img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
// Global Nav Menu
// Buttons
.v-btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-solid,
.btn-bg-white {
  font-size: 19px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 18px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  text-decoration: none !important;
}
.btn-bg-white {
  background-color: #ffffff;
}
.btn-bg-white:hover {
  background-color: #f6f6f6;
}
.btn-red {
  color: #cb5546 !important;
  box-shadow: 5px 5px 0px #994035;
}
.btn-green {
  color: #33a390 !important;
  box-shadow: 5px 5px 0px #267a6c;

  &:hover {
    color: #247775 !important;
  }
}
.btn-yellow {
  color: #b6a250 !important;
  box-shadow: 5px 5px 0px #b6a250;
}
.btn-solid-green {
  color: #ffffff;
  background-color: #33a390;
  box-shadow: 5px 5px 0px #267a6c;
}
.btn-solid-green:hover {
  background-color: #1f6054;
}
.btn-solid-yellow {
  color: #050505;
  background-color: #f2d86a;
  box-shadow: 5px 5px 0px #b6a250;
}
.btn-solid-yellow:hover {
  background-color: #ffffff;
}
.btn-solid-red {
  color: #050505;
  background-color: #c94b3b;
  box-shadow: 5px 5px 0px #994035;
}

// Default Layout
.default-layout-container {
  h1.bigger {
    font-size: 80px;
  }
  h1 {
    font-size: 60px;
    margin-bottom: 70px;
    color: #cc5546;
    font-family: "Hind";
    font-weight: 700;
    line-height: 1.1em;

    @media (max-width: 1023px) {
      font-size: 50px;
      margin-bottom: 35px;
    }
  }
  .content-two-columns {
    flex-direction: column;

    @media (min-width: 1099px) {
      flex-direction: row;
    }
  }
  .main-content {
    width: 100%;

    @media (min-width: 1100px) {
      width: 100vw;
      max-width: 650px;
    }
    .page-content {
      font-size: 21px;
    }
  }
  .side-bar {
    padding-top: 50px;

    @media (min-width: 1100px) {
      padding-top: 0;
      padding-left: 125px;
    }
  }
  padding: 100px 20px;

  @media (max-width: 1023px) {
    padding: 50px 20px;
  }
}

// Page Content
.page-content {
  p:not(:last-of-type) {
    margin-bottom: 30px;
  }

  p a {
    color: #267a6c;
  }

  p a:hover {
    text-decoration: underline dotted;
  }
}

// v-chip styles
.solutions-cat-filter-item {
  .v-chip__overlay,
  .v-chip__underlay {
    background-color: transparent;
  }

  .v-icon {
    margin-inline-start: 0px !important;
    margin-inline-end: 10px !important;
  }
}

.solutions-tag-filter-item {
  .v-chip__overlay,
  .v-chip__underlay {
    background-color: transparent;
    opacity: 0;
  }
}

// *** MD Content ***
// MD General
.md-content {
  position: relative;

  // Fonts
  h2,
  h3 {
    font-family: "Hind";
    font-weight: 700;
    line-height: 1.1em;
  }
  h2 {
    font-size: 40px;
    color: #33a390;
    margin-top: 50px;
    margin-bottom: 20px;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h3 {
    font-size: 30px;
    color: #247775;
    margin-bottom: 1em;
  }
  h4 {
    font-family: "Barlow";
    font-size: 25px;
    font-weight: 600;
    color: #cc5546;
    margin-bottom: 1em;
  }
  p {
    margin-bottom: 30px;
    font-size: 21px;

    &:first-child {
      font-size: 24px;
      font-weight: 500;
    }
  }
  a:hover {
    color: #c94b3b;
  }
  ol,
  ul {
    margin-bottom: 30px;
    padding-left: 1em;
    margin-left: 1em;

    li {
      margin-bottom: 5px;
    }
  }
  // Images
  figure.image-style-block-align-left {
    figcaption {
      left: 20px;
      font-size: 1rem;
      max-width: calc(100% - 40px);
    }
    @media (min-width: 600px) {
      max-width: 80%;
      figcaption {
        left: 20px;
        font-size: 1rem;
      }
    }
  }
  figure.image-style-block-align-right {
    figcaption {
      left: 20px;
      font-size: 1rem;
      max-width: calc(100% - 40px);
    }
    @media (min-width: 600px) {
      max-width: 80%;
      margin-left: 20%;
      figcaption {
        left: 20px;
        font-size: 1rem;
      }
    }
  }
  figure.image-style-align-left {
    figcaption {
      left: 20px;
      font-size: 1rem;
      max-width: calc(100% - 40px);
    }

    @media (min-width: 600px) {
      figcaption {
        left: 20px;
        font-size: 0.75rem;
        max-width: calc(100% - 60px);
      }
      float: left;
      max-width: 280px;
      padding: 10px 20px 0 0;
      margin-bottom: 30px;
    }
  }
  figure.image-style-align-right {
    figcaption {
      left: 20px;
      font-size: 1rem;
      max-width: calc(100% - 40px);
    }

    @media (min-width: 600px) {
      figcaption {
        left: 40px;
        font-size: 0.75rem;
        max-width: calc(100% - 60px);
      }
      float: right;
      max-width: 280px;
      padding: 10px 0 0 20px;
      margin-bottom: 30px;
    }
  }

  figure.image-style-align-center {
    width: 100vw;
    max-width: 1300px;
    margin-left: -20px;
    margin-right: 0;
    margin-bottom: 80px;
    position: relative;
    line-height: 1;
  }
  figure.image-style-align-center figcaption {
    font-size: 1rem;
    background-color: #050505;
    color: #ffffff;
    padding: 15px 20px;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    bottom: -1em;
    left: 20px !important;
  }
  @media (min-width: 1140px) {
    figure.image-style-align-center {
      margin-left: calc(0px - ((min(1300px, 100vw) - 1140px) / 2)) !important;
    }
    figure.image-style-align-center figcaption {
      left: calc((min(1300px, 100vw) - 1140px) / 2) !important;
      max-width: 1140px;
    }
  }

  figure.image-style-side {
    float: none;
    max-width: none;
    padding: 0px;
    width: 100%;
    margin-left: 0%;
    position: relative;
    padding-left: 0px;
    margin-bottom: 30px;
  }
  @media (min-width: 1140px) {
    figure.image-style-side {
      float: none;
      max-width: none;
      padding: 0px;
      width: calc(1140px - 100%);
      margin-left: 100%;
      position: absolute;
      padding-left: 40px;
      margin-bottom: 0px;

      figcaption {
        left: 60px;
        max-width: calc(100% - 80px);
      }
    }
  }

  figure {
    position: relative;
    margin-bottom: 50px;
  }

  figcaption {
    font-size: 1rem;
    background-color: #050505;
    color: #ffffff;
    padding: 15px 20px;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    bottom: -1em;
    left: 20px;
    line-height: 1;
    max-width: calc(100% - 40px);
  }

  // Blockquote
  blockquote {
    padding: 20px 30px;

    p {
      font-size: 23px;
      font-style: italic;
      color: #cc5546;
    }
  }

  // Horizontal Line
  hr {
    max-width: 68px;
    border-color: #247775;
    margin-bottom: 20px;
  }
}

// MD Home Page
#home-page {
  .md-content {
    p {
      font-size: 21px !important;
      font-weight: 500 !important;
      line-height: 1.5em !important;
    }
  }
}

// MD Methane Page
#methane-page {
  .intro-content-top.md-content p {
    max-width: 650px !important;
    margin-bottom: 36px !important;
  }

  .md-content {
    p {
      font-size: 21px;
      font-weight: 500;
      margin-bottom: 1em;
    }
    p:last-child {
      margin-bottom: 0;
    }

    p a {
      color: #050505;

      &:hover {
        text-decoration: dotted underline;
      }
    }
  }

  .methane-solutions-learn-more.md-content {
    max-width: 350px;
    height: 100%;
    margin-left: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    position: relative;

    @media (max-width: 599px) {
      margin: 0;
      min-height: 300px;
    }

    p {
      font-size: clamp(13px, 2vw, 20px);
      font-weight: 700;
      font-style: italic;
      color: #ffffff;
      z-index: 2 !important;
      padding-right: 25%;
      margin-bottom: 0;

      @media (max-width: 599px) {
        font-size: 16px;
      }

      a {
        color: #ffffff;
        text-decoration: underline;
        position: relative;

        &:hover {
          text-decoration: underline dotted;
        }
      }
      a:after {
        content: "";
        width: 20px;
        aspect-ratio: 1;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 448 512'%3E%3Cpath d='M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z'/%3E%3C/svg%3E");
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }
}

// References Content
.references-container {
  hr {
    max-width: 68px;
    border-color: #247775;
    margin-bottom: 20px;
  }
}
.references-content {
  h2,
  h3 {
    color: #247775;
    margin-bottom: 20px;
  }

  ul,
  ol {
    margin-left: 1em !important;
    font-size: 16px;
    line-height: 2;
    word-break: break-all;
  }
  a {
    color: #050505;

    &:hover {
      text-decoration: underline dotted;
    }
  }
}

// Sector Solutions Button Filter
.sector-solutions-block {
  list-style: none;

  .sector-solutions-item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    .sector-solutions-item-inner {
      display: flex;
      align-items: center;
    }
    .icon-wrapper {
      width: 85px;
      aspect-ratio: 1;
      border-radius: 50%;
      position: relative;

      .v-icon {
        font-size: 65px;
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(12%, -50%);

        .svg-inline--fa {
          width: 65px;
          height: 65px;
        }
        
        @media (max-width: 500px) {
          font-size: 50px;

          .svg-inline--fa {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    a {
      font-size: 21px;
      color: #050505;
      font-weight: 500;
      text-decoration: none;
      position: relative;
      margin-left: 30px;

      @media (max-width: 767px) {
        margin-left: 50px;
      }

      .v-icon {
        font-size: 0.7em;
        margin-left: 0.25em;
      }
    }
    a:hover {
      text-decoration: underline;
    }
  }
  .sector-solutions-agriculture .icon-wrapper {
    background-color: #33a28f;
  }
  .sector-solutions-energy .icon-wrapper {
    background-color: #f1d76a;
  }
  .sector-solutions-waste .icon-wrapper {
    background-color: #cb5546;
  }
  .sector-solutions-all .icon-wrapper {
    background-color: #247775;

    i {
      color: #ffffff;
    }
  }
}

// Solutions Index
#solutions-page {
  .md-content {
    a.read-more {
      color: #247775 !important;

      &:hover {
        text-decoration: underline dotted;
      }
    }
  }
}

.solutions-cat-filter-container {
  .v-icon {
    font-size: 20px;
  }
}
</style>
