// social benefits case type meta info
export default function socialCaseTypesConfig() {
    const { t } = useI18n();

    const socialCaseTypes = {
        cvd: {
            id: 'cvd',
            name: t('explorer.socialCases.caseTypes.cvd.name'),
            description: t('explorer.socialCases.caseTypes.cvd.description'),
        },
        heat: {
            id: 'heat',
            name: t('explorer.socialCases.caseTypes.heat.name'),
            description: t('explorer.socialCases.caseTypes.heat.description'),
        },
        respiratory: {
            id: 'respiratory',
            name: t('explorer.socialCases.caseTypes.respiratory.name'),
            description: t('explorer.socialCases.caseTypes.respiratory.description'),
        },
        total: {
            id: 'total',
            name: t('explorer.socialCases.caseTypes.total.name'),
            description: t('explorer.socialCases.caseTypes.total.description'),
        },
    };

    return socialCaseTypes;
}
