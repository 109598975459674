import locale_locales_locales_en from "../locales/en.js" assert { type: "js" };
import locale_locales_locales_es from "../locales/es.js" assert { type: "js" };
import locale_locales_locales_pt from "../locales/pt.js" assert { type: "js" };
export const localeCodes = ["en","es","pt"]

export const localeMessages = {
  "en": [{ key: "../locales/en.js", load: () => Promise.resolve(locale_locales_locales_en) }],
  "es": [{ key: "../locales/es.js", load: () => Promise.resolve(locale_locales_locales_es) }],
  "pt": [{ key: "../locales/pt.js", load: () => Promise.resolve(locale_locales_locales_pt) }],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.bundle = Object({"compositionOnly":true,"runtimeOnly":false,"fullInstall":true,"dropMessageCompiler":false})
  nuxtI18nOptions.compilation = Object({"jit":true,"strictMessage":true,"escapeHtml":false})
  nuxtI18nOptions.customBlocks = Object({"defaultSFCLang":"json","globalSFCScope":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  const vueI18n = await vueI18nConfigLoader((() => import("../config/nuxtVuei18nConfig?hash=56fe094a&config=1" /* webpackChunkName: "__config_nuxtVuei18nConfig_56fe094a" */)))
  nuxtI18nOptions.vueI18n = vueI18n
  nuxtI18nOptions.vueI18n.messages ??= {}
  nuxtI18nOptions.locales = [Object({"code":"en","iso":"en","name":"English","files":["locales/en.js"],"hashes":["a3ac0234"],"types":["static"]}),Object({"code":"es","iso":"es","name":"Español","files":["locales/es.js"],"hashes":["73a8d3be"],"types":["static"]}),Object({"code":"pt","iso":"pt","name":"Português","files":["locales/pt.js"],"hashes":["059b235a"],"types":["static"]})]
  nuxtI18nOptions.defaultLocale = "es"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_and_default"
  nuxtI18nOptions.lazy = false
  nuxtI18nOptions.langDir = "locales"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = false
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = ""
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "config"
  nuxtI18nOptions.pages = Object({"about/index":Object({"en":"/about","es":"/sobre","pt":"/sobre"}),"casestudy/[CaseStudyURL]":Object({"en":"/case_study/[CaseStudyURL]","es":"/caso_de_estudio/[CaseStudyURL]","pt":"/estudo_de_caso/[CaseStudyURL]"}),"contact/index":Object({"en":"/contact","es":"/contacto","pt":"/contato"}),"content/[PageURL]":Object({"en":"/content/[PageURL]","es":"/contenido/[PageURL]","pt":"/conteudo/[PageURL]"}),"explore/[region]/[country]/[[sector]]":Object({"en":"/explore/[region]/[country]/[[sector]]","es":"/explorar/[region]/[country]/[[sector]]","pt":"/explorar/[region]/[country]/[[sector]]"}),"for_policy_makers/index":Object({"en":"/for_policy_makers","es":"/para_los_responsables_politicos","pt":"/para_os_decisores_politicos"}),"methane101/index":Object({"en":"/methane101","es":"/metano","pt":"/metano"}),"sector/[PageURL]":Object({"en":"/sector/[PageURL]","es":"/sector/[PageURL]","pt":"/setor/[PageURL]"}),"solutions_overview/[[sector]]":Object({"en":"/solutions_overview/[[sector]]","es":"/descripcion_soluciones/[[sector]]","pt":"/visao_geral_solucoes/[[sector]]"}),"solutions/[SolutionURL]":Object({"en":"/solutions/[SolutionURL]","es":"/soluciones/[SolutionURL]","pt":"/solucoes/[SolutionURL]"})})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  nuxtI18nOptions.parallelPlugin = false
  nuxtI18nOptions.i18nModules = []
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),bundle: Object({"compositionOnly":true,"runtimeOnly":false,"fullInstall":true,"dropMessageCompiler":false}),compilation: Object({"jit":true,"strictMessage":true,"escapeHtml":false}),customBlocks: Object({"defaultSFCLang":"json","globalSFCScope":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false,parallelPlugin: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"code":"en","iso":"en","name":"English","files":["locales/en.js"],"hashes":["a3ac0234"],"types":["static"]}),Object({"code":"es","iso":"es","name":"Español","files":["locales/es.js"],"hashes":["73a8d3be"],"types":["static"]}),Object({"code":"pt","iso":"pt","name":"Português","files":["locales/pt.js"],"hashes":["059b235a"],"types":["static"]})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = true
export const parallelPlugin = false
