// tree-shaking the fontawesome
import { fa } from 'vuetify/iconsets/fa-svg'
import { library, config } from '@fortawesome/fontawesome-svg-core' // Core SVG
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faCaretDown,
    faCow as faCowFree,
} from "@fortawesome/free-solid-svg-icons";

// fa pro solid imports
import {
    faAppleCore as faAppleCoreSolid,
    faBackwardFast as faBackwardFastSolid,
    faBackwardStep as faBackwardStepSolid,
    faCircleInfo as faCircleInfoSolid,
    faIndustryWindows as faIndustryWindowsSolid,
    faForwardStep as faForwardStepSolid,
    faLeaf as faLeafSolid,
    faPause as faPauseSolid,
    faPlay as faPlaySolid,
} from "@fortawesome/pro-solid-svg-icons";

// fa regular imports
import {
    faCow as faCowRegular,
    faIndustryWindows as faIndustryWindowsRegular,
    faAppleCore as faAppleCoreRegular,
    faArrowRight as faArrowRightRegular,
} from "@fortawesome/pro-regular-svg-icons";

// fa pro light imports
import {
    faAppleCore,
    faBars,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faCircleInfo,
    faCow,
    faEnvelope,
    faExclamationTriangle,
    faGlobe,
    faIndustryWindows,
    faMagnifyingGlass,
    faSeedling,
    faTimes,
    faUser,
} from "@fortawesome/pro-light-svg-icons";

// add all imported icons to library
library.add(
    faAppleCore,
    faAppleCoreSolid,
    faAppleCoreRegular,
    faBackwardFastSolid,
    faBackwardStepSolid,
    faBars,
    faCaretDown,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faCircleInfo,
    faCircleInfoSolid,
    faCow,
    faCowFree,
    faCowRegular,
    faEnvelope,
    faExclamationTriangle,
    faForwardStepSolid,
    faGlobe,
    faIndustryWindows,
    faIndustryWindowsSolid,
    faIndustryWindowsRegular,
    faLeafSolid,
    faMagnifyingGlass,
    faPauseSolid,
    faPlaySolid,
    faSeedling,
    faTimes,
    faUser,
    faArrowRightRegular
);

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

// vuetify icon aliases
const aliases = {
    appleCore: "fal fa-apple-core",
    appleCoreSolid: "fa-solid fa-apple-core",
    appleCoreRegular: "fa-regular fa-apple-core",
    arrowRightRegular: "fa-regular fa-arrow-right",
    backwardFastSolid: "fa-solid fa-backward-fast",
    backwardStepSolid: "fa-solid fa-backward-step",
    bars: "fal fa-bars",
    caretDown: "fas fa-caret-down",
    check: "fal fa-check",
    chevronLeft: "fal fa-chevron-left",
    chevronRight: "fal fa-chevron-right",
    chevronDown: "fal fa-chevron-down",
    circleInfo: "fal fa-circle-info",
    circleInfoSolid: "fa-solid fa-circle-info",
    cow: "fal fa-cow",
    cowFree: "fas fa-cow",
    cowRegular: "fa-regular fa-cow",
    envelope: "fal fa-envelope",
    exclamationTriangle: "fal fa-exclamation-triangle",
    forwardStepSolid: "fa-solid fa-forward-step",
    globe: "fal fa-globe",
    industryWindows: "fal fa-industry-windows",
    industry: "fas fa-industry-alt",
    industryWindowsSolid: "fa-solid fa-industry-windows",
    industryWindowsRegular: "fa-regular fa-industry-windows",
    leafSolid: "fa-solid fa-leaf",
    magnifyingGlass: "fal fa-magnifying-glass",
    next: "fal fa-chevron-right",
    pauseSolid: "fa-solid fa-pause",
    playSolid: "fa-solid fa-play",
    prev: "fal fa-chevron-left",
    seedling: "fal fa-seedling",
    times: "fal fa-times",
    user: "fal fa-user",
};

// export for nuxt
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
    nuxtApp.hook('vuetify:configuration', ({ vuetifyOptions }) => {
        vuetifyOptions.icons = {
            defaultSet: 'fa',
            aliases: aliases,
            sets: {
                fa,
            }
        }
    })
})
