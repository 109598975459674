/**
 * generic utilities for managing arrays and objects
 */

export default function genericUtils() {
  /**
   * returns an Object whose keys are regular Objects as an Array of objects, optionally filtered to a list of keys
   * Returned Objects are sorted alphabetically if they contain a 'name' key
   * @param {[String]} keys - an array of energySource id's (strings) to filter the list to
   * @returns an array of energySource objects
   */
  const getObjectKeysAsArray = (Obj, keys = null) => {
    if (keys !== null && !Array.isArray(keys)) {
      console.warn("filter keys not passed as an array to getObjectKeysArray");
    }
    const keysArray =
      keys !== null ? keys.map((_key) => Obj[_key]) : Object.values(Obj);
    return "name" in keysArray[0]
      ? keysArray.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      : keysArray;
  };

  /**
   * Converts an object to an array containing all its values.
   * @param {object} object - The object to convert
   * @returns {array} An array contains all the values of the input object.
   */
  function convertObjectToArray(object) {
    return Object.keys(object).map((key) => ({
      ...object[key],
    }));
  };

  /**
   * a simple debounce function
   * @param {*} func
   * @param {*} timeout
   * @returns
   */
  function debounce(func, timeout) {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  }

  return { getObjectKeysAsArray, convertObjectToArray, debounce };
}
