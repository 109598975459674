// config and data for pathways explorer tool
import caseStudiesI18nSlugsConfig from "@/config/caseStuiesI18nSlugs";

export default function useCaseStudiesI18nConfig() {
  const caseStudiesSlugs = caseStudiesI18nSlugsConfig();

  function getCaseStudiesXRefs(locale, slug) {
    const foundItem = caseStudiesSlugs.find(item => {
      const itemSlug = item.slugs[locale];
      return itemSlug && itemSlug === slug;
    });

    return foundItem || null;
  }

  return {
    caseStudiesSlugs,
    getCaseStudiesXRefs,
  };
}
